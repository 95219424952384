import * as React from 'react';
import styled from 'styled-components';
import * as qs from 'querystring';

import { useLazyQuery } from '@apollo/client';

import { SearchInput } from './ui/SearchBar';
import { usePost } from '../../../../utils/hooks/usePost';
import { useHistory, useLocation } from 'react-router';

import {
  SearchResultWrapper,
  SearchResultInsideWrapper,
  SearchResultLabel
} from './ui/SearchWrapper';

import { SearchTabContent } from './ui/SearchTabContent';
import { StyledPropertyListWrapper } from '../../../partials/cluster/PropertyList';
import { PropertyCard } from '../../cards/cluster/Property';
import { Broker } from './ui/BrokerItem';
import { Office } from './ui/OfficeItem';

import {
  AssignmentType,
  Department,
  DepartmentTag,
  Employee,
  Estate,
  SearchGlobalQuery,
  SearchGlobalQueryVariables
} from '../../../../__types__/generated/cluster';
import { SEARCH_GLOBAL } from '../../../../../client/__graphql__/cluster/queries/search/global';
import { ENV_CONFIG } from '../../../../__config__/env';
import { client } from '../../../../__graphql__/clientCluster';
import {
  GlobalSearch as GlobalSearchTypes,
  GlobalSearchVariables
} from '../../../../__types__/generated/GlobalSearch';
import { GLOBAL_SEARCH_QUERY } from '../../../../../client/__graphql__/queries/globalSearch';
import {
  PropertySortByType,
  SearchTypes
} from '../../../../__types__/generated/globalTypes';
import { getDefaultAssignmentTypes } from '../../../containers/search/util/filter/getDefaultAssignmentTypes';
import { useDebounce } from '../../../../utils/hooks/useDebounce';

type SearchType = 'global' | 'broker_office';
enum ScrollToContent {
  office,
  estate,
  employee,
  unknown
}

export const SearchContext = React.createContext({});

interface IFilter {
  projectOnly: boolean;
}

export type SearchInString = 'property' | 'office' | 'broker' | 'landingpage';

interface IArgs {
  filter?: IFilter;
  searchString: string;
  searchIn: Array<SearchInString>;
  officeCurrentPage: number;
  officeLimit?: number;
  brokerCurrentPage: number;
  propertyCurrentPage: number;
}

interface IResult<T> {
  total: number;
  open: boolean;
  list: T[];
}

export const GlobalSearch = ({ type }: { type: SearchType }) => {
  const history = useHistory();
  const location = useLocation();

  const [alterTab, setAlterTab] = React.useState(true);
  const [init, setInit] = React.useState(true);
  const [isSearching, setIsSearching] = React.useState(false);
  const [pageChanged, setPageChanged] = React.useState(false);
  const [itemsPrPage, setItemsPrPage] = React.useState(21);
  const [scrollToContent, setScrollToContent] = React.useState<ScrollToContent>(
    ScrollToContent.unknown
  );

  const [query, setQuery] = React.useState('');

  const propertyRef = React.useRef<HTMLDivElement | null>(null);
  const [propertyTabOpen, setPropertyTabOpen] = React.useState(false);
  const [propertyCurrentPage, setPropertyCurrentPage] = React.useState(0);
  const [propertyResult, setPropertyResult] = React.useState<
    IResult<Partial<Estate>>
  >({
    total: 0,
    open: false,
    list: []
  });

  const officeRef = React.useRef<HTMLDivElement | null>(null);
  const [officeTabOpen, setOfficeTabOpen] = React.useState(false);
  const [officeCurrentPage, setOfficeCurrentPage] = React.useState(0);
  const [officeResult, setOfficeResult] = React.useState<
    IResult<Partial<Department>>
  >({
    total: 0,
    open: false,
    list: []
  });

  const brokerRef = React.useRef<HTMLDivElement | null>(null);
  const [brokerTabOpen, setBrokerTabOpen] = React.useState(false);
  const [brokerCurrentPage, setBrokerCurrentPage] = React.useState(0);
  const [brokerResult, setBrokerResult] = React.useState<
    IResult<Partial<Employee>>
  >({
    total: 0,
    open: false,
    list: []
  });

  const [showAutocomplete, setShowAutocomplete] = React.useState(false);
  const [autocomplete, setAutocomplete] = React.useState([]);

  const debouncedQuery = useDebounce<typeof query>(query, 300);

  React.useEffect(() => {
    if (!init) {
      search(debouncedQuery);
    }
  }, [debouncedQuery]);

  const [searchQL, { data: searchData, loading }] = useLazyQuery<
    SearchGlobalQuery,
    SearchGlobalQueryVariables
  >(SEARCH_GLOBAL, {
    client,
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
    onCompleted: ({ search }) => {
      setPropertyResult((prevState) => ({
        ...prevState,
        total: search?.estate?.total ?? 0,
        list: search?.estate?.list ?? [],
        open: search?.estate?.total ? true : false
      }));
      setBrokerResult((prevState) => ({
        ...prevState,
        total: search?.employee?.total ?? 0,
        list: search?.employee?.list ?? [],
        open: search?.employee?.total ? true : false
      }));
      setOfficeResult((prevState) => ({
        ...prevState,
        total: search?.brand?.total ?? 0,
        list: search?.brand?.list ?? [],
        open: search?.brand?.total ? true : false
      }));

      if (alterTab) {
        setBrokerTabOpen(search?.employee?.total ? true : false);
        setOfficeTabOpen(search?.brand?.total ? true : false);
        setPropertyTabOpen(search?.estate?.total ? true : false);
        setAlterTab(false);
      }
      setInit(false);
    }
  });

  const onScrollIntoView = (offsetTop?: number) => {
    if (!offsetTop) return;
    const x = 77;
    window.scrollTo({
      behavior: 'smooth',
      top: offsetTop - x > 0 ? offsetTop - x : 0
    });
  };

  const [legacySearchQL, { data: legacyData, loading: legacyLoading }] =
    useLazyQuery<GlobalSearchTypes, GlobalSearchVariables>(
      GLOBAL_SEARCH_QUERY,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ search }) => {
          if (search?.result && search?.result?.landingpages?.list?.length) {
            const page =
              search?.result?.landingpages?.list?.find((e) => e) ?? null;
            if (page) {
              history.replace({
                pathname: location.pathname,
                search: '?q='
              });
              window.location.href = page;
            }
          }
        }
      }
    );

  // Get query params from url
  React.useEffect(() => {
    if (location?.search) {
      const parsedQuery: any = qs.parse(location.search);
      const q: string = parsedQuery['?q'];
      setQuery(q);
      setAlterTab(true);
      search(q);
    }
  }, [location]);

  // Search when query chainging and searching is true
  const reset = () => {
    setQuery('');
    setAlterTab(true);
    setPropertyCurrentPage(0);
    setBrokerCurrentPage(0);
    setOfficeCurrentPage(0);
    setBrokerResult({ total: 0, list: [], open: false });
    setPropertyResult({ total: 0, list: [], open: false });
    setOfficeResult({ total: 0, list: [], open: false });
    if (location?.search?.match(/q=/gi)) {
      history.push({
        pathname: location.pathname,
        search: '?q='
      });
    } else {
      history.push({
        pathname: location.pathname,
        search: '?q='
      });
    }
  };

  const isEverythingPage = () => {
    return location?.search === '?alle';
  };

  const isNewDevelopmentSalePage = () => {
    return location?.search === '?nybygg-salg';
  };

  const getOfficeLimit = () => {
    if (isEverythingPage() && !query) return 50;
    return itemsPrPage;
  };

  const search = (providedQuery?: string) => {
    const tags = isNewDevelopmentSalePage()
      ? [DepartmentTag.NewDevelopment]
      : undefined;
    searchQL({
      variables: {
        finn: true,
        input: {
          brandId: ENV_CONFIG.BRAND_ID,
          query: providedQuery?.trim()?.length ? providedQuery : query
        },
        brandFilter: {
          include: { type: ['department'] },
          tags,
          pagination: {
            page: officeCurrentPage,
            limit: getOfficeLimit()
          }
        },
        employeeFilter: {
          brandTags: tags,
          pagination: {
            page: brokerCurrentPage,
            limit: itemsPrPage
          }
        },
        estateFilter: {
          isProjectUnit: false,
          published: true,
          assignmentTypes: isNewDevelopmentSalePage()
            ? [
                AssignmentType.NewDevelopment,
                AssignmentType.NewDevelopmentCommercial,
                AssignmentType.NewDevelopmentLeisure
              ]
            : getDefaultAssignmentTypes(),
          pagination: {
            page: propertyCurrentPage,
            limit: itemsPrPage
          }
        }
      }
    });

    const landingPageSearch =
      (location.search.match(/q=/g) || type === 'global') &&
      !(!query && (isNewDevelopmentSalePage() || isEverythingPage()));

    if (landingPageSearch) {
      legacySearchQL({
        variables: {
          input: {
            searchIn: [
              {
                type: SearchTypes.landingpage,
                pagination: { limit: itemsPrPage, page: 0 }
              }
            ],
            query: providedQuery ?? query,
            filter: {
              property: { sort: PropertySortByType.PUBLISHEDNEWOLD }
            }
          }
        }
      });
    }
  };

  React.useEffect(() => {
    if (pageChanged) {
      search();
    }
  }, [brokerCurrentPage, officeCurrentPage, propertyCurrentPage, pageChanged]);

  return (
    <SearchContext.Provider
      value={{
        type,
        reset,
        search,
        query,
        showAutocomplete,
        setShowAutocomplete,
        setQuery,
        propertyTabOpen,
        propertyResult,
        setPropertyCurrentPage,
        setPropertyTabOpen,
        propertyCurrentPage,
        brokerTabOpen,
        brokerResult,
        brokerCurrentPage,
        setBrokerCurrentPage,
        setBrokerTabOpen,
        officeTabOpen,
        officeResult,
        officeCurrentPage,
        setOfficeCurrentPage,
        setOfficeTabOpen,
        isSearching,
        setIsSearching
      }}
    >
      <SearchInput
        placeholder="Søk sted, postnummer eller navn"
        autocomplete={autocomplete}
        loading={loading}
      />
      {brokerResult?.total || officeResult?.total || propertyResult?.total ? (
        <SearchResultWrapper>
          <SearchResultInsideWrapper>
            <SearchResultLabel>Resultat</SearchResultLabel>
            <StyledContent ref={officeRef}>
              <SearchTabContent
                total={officeResult?.total}
                showPagination={true}
                label="Kontorer"
                open={officeTabOpen}
                pagination={{
                  currentPage: officeCurrentPage,
                  maxPages: 1000,
                  itemsPerPage: getOfficeLimit(),
                  total: searchData?.search?.brand?.total ?? 0,
                  pageOnChange: ({ selected }: { selected: number }) => {
                    setOfficeCurrentPage(selected);
                    setPageChanged(selected !== officeCurrentPage);
                    onScrollIntoView(officeRef?.current?.offsetTop);
                  }
                }}
                onClick={() => setOfficeTabOpen(!officeTabOpen)}
              >
                {officeResult?.list?.map((office, index) => {
                  const {
                    alias,
                    name,
                    municipalityName,
                    postalPlace,
                    tags,
                    referenceUrls
                  } = office;
                  if (!alias) return null;
                  return (
                    <Office
                      key={`office_${index}`}
                      municipality={municipalityName ?? postalPlace}
                      name={name}
                      naering={
                        tags?.includes(DepartmentTag.Commercial) ?? false
                      }
                      url={referenceUrls?.departmentUrl ?? ''}
                      alias={alias}
                    />
                  );
                })}
              </SearchTabContent>
            </StyledContent>
            <StyledContent ref={brokerRef}>
              <SearchTabContent
                total={brokerResult?.total}
                showPagination={true}
                label="Meglere"
                open={brokerTabOpen}
                pagination={{
                  currentPage: brokerCurrentPage,
                  maxPages: 1000,
                  itemsPerPage: itemsPrPage,
                  total: searchData?.search?.employee?.total ?? 0,
                  pageOnChange: ({ selected }: { selected: number }) => {
                    setBrokerCurrentPage(selected);
                    setPageChanged(selected !== brokerCurrentPage);
                    onScrollIntoView(brokerRef?.current?.offsetTop);
                  }
                }}
                onClick={() => setBrokerTabOpen(!brokerTabOpen)}
              >
                {brokerResult?.list?.map((employee, index) => {
                  if (!employee?.employeeAlias) return null;
                  return <Broker key={`broker_${index}`} data={employee} />;
                })}
              </SearchTabContent>
            </StyledContent>

            {!isEverythingPage() ? (
              <StyledContent ref={propertyRef}>
                <SearchTabContent
                  total={propertyResult.total}
                  showPagination={true}
                  label="Eiendommer"
                  open={propertyTabOpen}
                  pagination={{
                    currentPage: propertyCurrentPage,
                    maxPages: 1000,
                    itemsPerPage: itemsPrPage,
                    total: searchData?.search?.estate?.total ?? 0,
                    pageOnChange: ({ selected }: { selected: number }) => {
                      setPropertyCurrentPage(selected);
                      setPageChanged(selected !== propertyCurrentPage);
                      onScrollIntoView(propertyRef?.current?.offsetTop);
                    }
                  }}
                  onClick={() => setPropertyTabOpen(!propertyTabOpen)}
                >
                  <StyledPropertyListWrapper>
                    {propertyResult?.list?.map((estate, index) => {
                      return (
                        <PropertyCard
                          key={`property_${estate?.estateId}_${index}`}
                          item={estate}
                          currentPage={propertyCurrentPage}
                        />
                      );
                    })}
                  </StyledPropertyListWrapper>
                </SearchTabContent>
              </StyledContent>
            ) : null}
          </SearchResultInsideWrapper>
        </SearchResultWrapper>
      ) : query &&
        !loading &&
        (brokerResult?.total === 0 ||
          officeResult?.total === 0 ||
          propertyResult?.total === 0) ? (
        <SearchResultWrapper>
          <SearchResultInsideWrapper>
            <SearchResultLabel style={{ marginBottom: 0 }}>
              INGEN RESULTATER
            </SearchResultLabel>
          </SearchResultInsideWrapper>
        </SearchResultWrapper>
      ) : null}
    </SearchContext.Provider>
  );
};

const StyledContent = styled.div`
  display: flex;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;
