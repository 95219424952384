import * as React from 'react';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { PropertyCard } from '../../ui/cards/cluster/Property';
import { Checkbox } from '../../ui/form/Checkbox';
import { Pagination } from '../../ui/pagination/Pagination';
import { useScrollToElement } from '../../../utils/hooks/useScrollToElement';
import { usePropertyListContext } from '../../../utils/hooks/usePropertyListContext';
import { SEARCH_ESTATE } from '../../../../client/__graphql__/cluster/queries/search/estate';
import {
  Estate,
  SearchEstateQuery,
  SearchEstateQueryVariables,
  SearchEstateSort
} from '../../../__types__/generated/cluster';
import { client } from '../../../__graphql__/clientCluster';
import { ENV_CONFIG } from '../../../__config__/env';
import { getDefaultAssignmentTypes } from '../../containers/search/util/filter/getDefaultAssignmentTypes';

type ListType = 'office' | 'broker';

interface IPropertyList {
  type: ListType;
  employeeAliases?: string[] | string;
  departmentAliases?: string[] | string;
  defaultSold?: boolean;
  showCheckboxes?: boolean;
  hideBorderTop?: boolean;
  forwardRef?: { current: HTMLDivElement | null };
}

export const PropertyList: React.FC<IPropertyList> = ({
  type,
  employeeAliases,
  departmentAliases,
  defaultSold = false,
  showCheckboxes = true,
  hideBorderTop,
  forwardRef
}) => {
  const { scrollTo } = useScrollToElement();
  const { sold, setSold, page, setPage } = usePropertyListContext();
  const itemsPerPage: number = 12;
  const [total, setTotal] = React.useState(0);
  const [list, setList] = React.useState<Partial<Estate>[]>([]);

  React.useEffect(() => {
    setSold(defaultSold);
  }, [defaultSold]);

  const getVariables = (
    sold: boolean,
    page: number
  ): SearchEstateQueryVariables | undefined => {
    const eAlias: string[] = [];
    const dAlias: string[] = [];
    switch (type) {
      case 'broker':
        eAlias.push(...getArray(employeeAliases));
        break;
      case 'office':
        dAlias.push(...getArray(departmentAliases));
        break;
    }
    if (!eAlias?.length && !dAlias?.length) return;
    return {
      finn: true,
      input: { brandId: ENV_CONFIG?.BRAND_ID },
      filter: {
        sold: sold,
        isProjectUnit: false,
        published: true,
        assignmentTypes: getDefaultAssignmentTypes(),
        employeeAliases: eAlias?.length ? eAlias : undefined,
        departmentAliases: dAlias?.length ? dAlias : undefined,
        sort: sold
          ? SearchEstateSort.RecentlySold
          : SearchEstateSort.PublishedNewOld,
        pagination: { limit: itemsPerPage, page: page }
      }
    };
  };

  const getArray = (values?: string | string[]) => {
    if (!values || !values?.length) return [];
    if (Array.isArray(values)) return values?.filter((e) => e?.trim()?.length);
    if (!values?.trim()?.length) return [];
    return [values];
  };

  const contains = (values?: string | string[], searchFor?: string) => {
    if (!values || !values?.length || !searchFor?.length) return false;
    if (Array.isArray(values)) return values?.includes(searchFor);
    return values === searchFor;
  };

  const [fetchData, { data, loading }] = useLazyQuery<
    SearchEstateQuery,
    SearchEstateQueryVariables
  >(SEARCH_ESTATE, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted: ({ search }) => {
      setTotal(search?.estate?.total ? search?.estate?.total : 0);
      setList(search?.estate?.list ?? []);
    }
  });

  React.useEffect(() => {
    const variables = getVariables(sold, page);
    if (variables) {
      fetchData({
        variables
      });
    }
  }, [, sold, page]);

  React.useEffect(() => {
    const alias = 'bergensentrum';
    if (type === 'broker' && contains(departmentAliases, alias)) {
      if (!data?.search?.estate?.total) {
        fetchData({
          variables: {
            finn: true,
            input: {
              brandId: ENV_CONFIG?.BRAND_ID
            },
            filter: {
              sold: sold,
              published: true,
              isProjectUnit: false,
              assignmentTypes: getDefaultAssignmentTypes(),
              departmentAliases: [alias],
              sort: sold
                ? SearchEstateSort.RecentlySold
                : SearchEstateSort.PublishedNewOld,
              pagination: { limit: itemsPerPage, page: page }
            }
          }
        });
      }
    }
  }, [data, departmentAliases]);

  return (
    <StyledPropertyListOuterWrapper
      hideBorderTop={hideBorderTop}
      className="property-list"
      ref={forwardRef}
    >
      {showCheckboxes ? (
        <StyledCheckboxGroup>
          <Checkbox
            className="checkbox"
            onChange={() => null}
            checked={!sold}
            label="Eiendom til salgs"
            labelPos="right"
            onClick={() => {
              if (!sold) return;
              setPage(0);
              setSold(false);
            }}
          />
          <Checkbox
            className="checkbox"
            onChange={() => null}
            checked={sold}
            label="Eiendom solgt"
            labelPos="right"
            onClick={() => {
              if (sold) return;
              setPage(0);
              setSold(true);
            }}
          />
        </StyledCheckboxGroup>
      ) : null}
      <StyledPropertyListWrapper
        className={!loading && !total ? 'no-items' : ''}
      >
        {!loading &&
          list?.map((item) => (
            <PropertyCard key={item.estateId} item={item} currentPage={1} />
          ))}

        {!loading && !total ? (
          <StyledNoProperties>Ingen eiendommer funnet..</StyledNoProperties>
        ) : null}
      </StyledPropertyListWrapper>
      {total ? (
        <Pagination
          center={true}
          currentPage={page}
          maxPages={total / itemsPerPage}
          total={total}
          itemsPerPage={itemsPerPage}
          pageOnChange={({ selected }) => {
            if (typeof scrollTo === 'function') {
              scrollTo({
                ref: forwardRef as unknown as { current: HTMLDivElement },
                distanceTop: 100,
                behaviour: 'auto'
              });
              setPage(selected);
            }
          }}
        />
      ) : null}
    </StyledPropertyListOuterWrapper>
  );
};

const StyledCheckboxGroup = styled.div`
  display: flex;
  padding: 20px 0;
  width: 100%;

  .checkbox {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledNoProperties = styled.p`
  width: 100%;
  font-size: 20px;
  text-align: center;
`;

const StyledPropertyListOuterWrapper = styled.div<any>`
  margin-top: 1.875rem;
  border-top: ${(props) =>
    props.hideBorderTop ? 'none' : '1px solid #242525'};
  width: 100%;
`;

export const StyledPropertyListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;
  min-height: 31.25rem;

  &::after {
    content: '';
    flex: auto;
  }

  .property-item {
    width: calc(33.3333% - 10px);

    &:nth-last-child(1) {
      margin-left: 15px;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(
      auto-fit,
      minmax(calc(100% / 3 - 10px), calc(100% / 3 - 10px))
    );
    grid-gap: 15px;

    .property-item {
      width: 100%;
      &:nth-last-child(1) {
        margin-left: auto;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .property-item {
      width: 100%;
      &:nth-last-child(1) {
        margin-left: auto;
      }
    }
    @supports (display: grid) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (min-width: 580px) and (max-width: 960px) {
    .property-item {
      width: calc(50% - 10px);
      &:nth-last-child(1) {
        margin-left: 15px;
      }
    }
    @supports (display: grid) {
      grid-template-columns: repeat(
        auto-fit,
        minmax(calc(100% / 2 - 10px), calc(100% / 2 - 10px))
      );
      .property-item {
        width: 100%;
        &:nth-of-type(even) {
          margin-left: 0;
        }
        &:nth-last-child(1) {
          margin-left: auto;
        }
      }
    }
  }

  &.no-items {
    padding: 50px 0;
    display: flex !important;

    h2 {
      width: 100%;
      text-align: center;
    }
  }
`;
