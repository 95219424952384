import styled from 'styled-components';

export const SearchResultWrapper = styled.div`
  margin-top: 14px;
  margin-bottom: 50px;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  background: #18191a;
  float: left;

  @media screen and (max-width: 600px) {
    padding: 0;
    background: none;
  }
`;

export const SearchResultLabel = styled.span`
  margin-bottom: 18px;
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  color: white;

  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }
`;

export const SearchResultInsideWrapper = styled.div`
  width: 100%;
  padding: 20px;

  @media screen and (max-width: 600px) {
    padding: 20px 0;
    border-top: solid 1px rgb(40, 40, 40);
  }
`;
