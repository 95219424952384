import { gql } from '@apollo/client';

export const GLOBAL_SEARCH_QUERY = gql`
  query GlobalSearch($input: SearchInput) {
    search(input: $input) {
      result {
        landingpages {
          total
          list
        }
        offices {
          total
          list {
            id
            name
            municipality
            naering
            urltag
          }
        }
        brokers {
          total
          list {
            id
            naering
            name
            email
            phone
            cellphone
            path
            image {
              full
            }
            office {
              id
              name
              title
              urltag
            }
            active
          }
        }
        properties {
          total
          list {
            id
            address
            place
            type
            assignmentType
            sold
            checklistpoints {
              info {
                pmExclusive
                teaser
              }
            }
            freetext {
              list {
                finnLocalArea
              }
            }
            office {
              info {
                urltag
              }
            }
            images {
              list {
                url
              }
            }
            location {
              municipality
              municipalityArea
              county
            }
            price {
              info {
                price
                priceTo
              }
            }
            area {
              prom
              promTo
              bra
              braTo
            }
            units {
              sold
              total
              available
            }
          }
        }
      }
    }
  }
`;
