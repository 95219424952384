import styled from 'styled-components';

export const SearchButton = styled.button`
  padding: 1em;
  font-weight: 300;
  background-color: #242525;
  border: none;
  color: #e8c893;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
  line-height: 1;
  display: block;
  border-radius: 0;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  font-family: Proxima-Nova, arial;
  outline: none;

  &:hover {
    background: #e8c893;
    color: black;
  }
`;

export const SearchButtonGroup = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;

  &.column {
    flex-flow: column;
  }
`;

export const SearchButtonGroupColumn = styled.div`
  width: 100%;
  padding-right: 15px;

  &:last-child {
    padding-right: 0;
  }
`;
