import * as React from 'react';
import qs from 'querystring';
import { useHistory, useLocation } from 'react-router';

interface IPropertyListContext {
  sold: boolean;
  setSold: (value: boolean) => void;
  page: number;
  setPage: (page: number) => void;
}

export const PropertyListContext = React.createContext<IPropertyListContext>({
  sold: false,
  setSold: () => {},
  page: 0,
  setPage: () => {}
});

enum Action {
  TOGGLE_SOLD = 'TOGGLE_SOLD',
  UPDATE_PAGE = 'UPDATE_PAGE'
}

type ReducerState = {
  sold: boolean;
  page: number;
};

type ToggleSoldAction = {
  type: Action.TOGGLE_SOLD;
  payload: { value: boolean; page: number };
};
type UpdatePageAction = {
  type: Action.UPDATE_PAGE;
  payload: number;
};
type ReducerAction = ToggleSoldAction | UpdatePageAction;

const reducer = (state: ReducerState, action: ReducerAction) => {
  switch (action.type) {
    case Action.TOGGLE_SOLD:
      return {
        ...state,
        sold: action.payload.value,
        page: action.payload.page
      };
    case Action.UPDATE_PAGE:
      return { ...state, page: action.payload };
    default:
      throw new Error('unknown action');
  }
};

const intitialState = { sold: false, page: 0 };

export const PropertyListContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const init = React.useRef(true);
  const [state, dispatch] = React.useReducer(reducer, intitialState);

  /* Get page param from history */
  React.useEffect(() => {
    const params = qs.parse(location.search.slice(1)) as unknown as {
      p: string;
      so: string;
    };
    if (params?.p) {
      dispatch({ type: Action.UPDATE_PAGE, payload: parseInt(params.p) });
    }
    if (params.so) {
      dispatch({
        type: Action.TOGGLE_SOLD,
        payload: { value: params.so === 'true', page: parseInt(params.p) }
      });
    }
    init.current = false;
  }, []);

  /* Add current page to history */
  React.useEffect(() => {
    if (!init.current) {
      let existing = {};
      let params = {};
      if (location.search) {
        existing = qs.parse(location.search.slice(1));
      }
      params = { ...existing, p: state.page, so: state.sold };
      history.replace({
        pathname: location.pathname,
        search: qs.stringify(params)
      });
    }
  }, [init.current, state]);

  return (
    <PropertyListContext.Provider
      value={{
        sold: state.sold,
        setSold: (value) =>
          dispatch({
            type: Action.TOGGLE_SOLD,
            payload: { value: value, page: 0 }
          }),
        page: state.page,
        setPage: (page) => dispatch({ type: Action.UPDATE_PAGE, payload: page })
      }}
    >
      {children}
    </PropertyListContext.Provider>
  );
};
