import { gql } from '@apollo/client';

export const SEARCH_GLOBAL = gql`
  query SearchGlobal(
    $input: SearchInput!
    $brandFilter: SearchFilterBrand
    $employeeFilter: SearchFilterEmployee
    $estateFilter: SearchFilterEstate
    $finn: Boolean
  ) {
    search(input: $input) {
      brand(filter: $brandFilter) {
        total
        list {
          alias
          brandId
          email
          name
          postalPlace
          tags
          municipalityName
          referenceUrls {
            departmentUrl
          }
        }
      }
      employee(filter: $employeeFilter) {
        total
        list {
          brandId
          employeeAlias
          employeeId
          name
          mobilePhone
          title
          image {
            url
          }
          departments {
            alias
            brandId
            name
          }
        }
      }
      estate(filter: $estateFilter) {
        total
        list {
          estateId
          type
          isProjectMaster
          ownershipType
          assignmentNumber
          assignmentType
          assignmentTypeMapped
          streetAddress
          municipalityArea
          municipalityName
          countyName
          localArea
          postalPlace
          sold
          soldAt
          published
          publishedAt
          images(finn: $finn) {
            list {
              imageId
              url
            }
          }
          prices {
            askingPrice
            askingPriceTo
          }
          size {
            usableArea
            usableAreaTo
            primaryArea
            primaryAreaTo
            grossArea
            grossAreaTo
            grossInternalArea
            grossInternalAreaTo
          }
          plot {
            size
          }
          projectStatistics {
            available
            sold
            total
          }
          checklist {
            type
            checked
          }
        }
      }
    }
  }
`;
