import * as React from 'react';
import styled from 'styled-components';
import { ArrowIcon } from '../../../icons/Arrow';
import { Pagination } from '../../../pagination/Pagination';

interface IPageOnChangeArgs {
  selected: number;
}
interface IPagination {
  currentPage: number;
  maxPages: number;
  total: number;
  itemsPerPage: number;
  pageOnChange: any;
}

interface ITab {
  total: number;
  showPagination: boolean;
  label: string;
  open: boolean;
  pagination: IPagination;
  onClick?: () => void;
}

export const SearchTabContent: React.FC<ITab> = React.memo(
  ({ total, showPagination, open, label, pagination, children, onClick }) => {
    if (!total) return null;
    return (
      <StyledTabWrapper>
        <StyledTabHeading onClick={onClick}>
          <StyledTabHeadingLabel>{label}</StyledTabHeadingLabel>
          <StyledTabHeadingCount>
            <span>{total ?? 0} treff</span>
            <ArrowIcon className={open ? 'icon open' : 'icon'} />
          </StyledTabHeadingCount>
        </StyledTabHeading>
        {open ? (
          <StyledTabContent>
            {children}
            {total && pagination && showPagination ? (
              <Pagination
                center={true}
                currentPage={pagination?.currentPage}
                maxPages={pagination?.total / pagination?.itemsPerPage}
                total={total}
                itemsPerPage={pagination?.itemsPerPage}
                pageOnChange={pagination.pageOnChange}
              />
            ) : null}
          </StyledTabContent>
        ) : null}
      </StyledTabWrapper>
    );
  }
);

const StyledTabWrapper = styled.div`
  width: 100%;
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledTabHeading = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
  background: #e5c88f;
  color: black;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
`;

const StyledTabHeadingLabel = styled.div`
  font-size: 18px;
  font-weight: 400;
`;

const StyledTabHeadingCount = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;

  span {
    float: left;
  }

  .icon {
    position: relative;
    transition: transform 175ms ease-in-out;
    margin-left: 10px;
    width: 15px;
    height: 15px;
    transform: rotate(0deg);
    float: left;

    svg {
      position: absolute;
      width: 15px;
      height: 15px;
    }

    &.open {
      transform: rotate(-180deg);
    }

    path {
      stroke-width: 4px;
    }
  }
`;

const StyledTabContent = styled.div`
  width: 100%;
  padding: 10px 0;

  @media screen and (max-width: 600px) {
    padding: 0 0 10px 0;
  }
`;
