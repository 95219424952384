import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { SearchContext } from '../Index';

import { BarLoader } from 'react-spinners';
import { lighten } from 'polished';
import { CloseIcon } from '../../../icons/Close';

import {
  SearchButton,
  SearchButtonGroup,
  SearchButtonGroupColumn
} from './Button';
import { useHistory, useLocation } from 'react-router';
import { LoadingBar } from '../../../loaders/LoadingBar';

interface IInputProps {
  placeholder: string;
  autocomplete: any;
  loading: boolean;
  style?: any;
  className?: string;
}

export const SearchInput: React.FC<IInputProps> = React.memo(
  ({ placeholder, autocomplete, loading, style, className }) => {
    const {
      type,
      search,
      showAutocomplete,
      reset,
      query = '',
      setQuery
    }: any = React.useContext(SearchContext);

    const history = useHistory();
    const location = useLocation();

    const inputRef: any = React.useRef(null);
    const autocompleteListRef: any = React.useRef(null);
    const outerInputRef: any = React.useRef(null);

    const [autocompleteSelected, setAutocompleteSelected] = React.useState(-1);

    return (
      <StyledSearchInputWrapper ref={outerInputRef}>
        <form
          action="*"
          onSubmit={(e) => {
            e.preventDefault();
            inputRef.current.blur();
            if (typeof search === 'function') {
              search();
              history.push({
                pathname: location.pathname,
                search: `?q=${query}`
              });
            }
          }}
        >
          <SearchField
            className={className}
            style={style}
            ref={inputRef}
            placeholder={placeholder}
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 39 && autocomplete) {
                if (autocomplete[0]?.options[0]?.text) {
                  const value = autocomplete[0].options[0].text;
                  setQuery(value);
                  search(value);
                  history.push({
                    pathname: location.pathname,
                    search: `?q=${value}`
                  });
                  if (typeof inputRef?.current?.blur === 'function') {
                    inputRef.current.blur();
                  }
                }
              }
              if (e.keyCode === 40) {
                e.preventDefault();
                if (autocompleteListRef && autocompleteListRef.current) {
                  autocompleteListRef.current.focus();
                  setAutocompleteSelected(0);
                }
              }
            }}
          />
          {loading ? (
            <LoadingBar loading={loading} className="loading-bar" />
          ) : null}
          {autocomplete && autocomplete[0] && autocomplete[0].options[0] ? (
            <AutocompletePlaceholder>
              {autocomplete[0].options[0].text}
            </AutocompletePlaceholder>
          ) : null}
          <div
            className="reset"
            onClick={() => {
              if (typeof reset === 'function') {
                reset();
              }
            }}
          >
            <CloseIcon className="reset-icon" />
          </div>
        </form>
        <SearchFieldBackground />
        {type === 'broker_office' ? (
          <SearchButtonGroup style={{ float: 'left' }}>
            <SearchButtonGroupColumn>
              <SearchButton
                onClick={() => {
                  window.location.href =
                    'https://privatmegleren.no/kontorer/inarheten?q=';
                }}
              >
                Søk i nærheten
              </SearchButton>
            </SearchButtonGroupColumn>
            <SearchButtonGroupColumn>
              <SearchButton
                onClick={() => {
                  history.push({
                    pathname: location.pathname,
                    search: '?alle'
                  });
                }}
              >
                Alle kontorer
              </SearchButton>
            </SearchButtonGroupColumn>
          </SearchButtonGroup>
        ) : null}
        {autocomplete[0]?.options?.length && showAutocomplete ? (
          <Autocomplete
            tabIndex={-1}
            ref={autocompleteListRef}
            onKeyDown={(e: any) => {
              e.preventDefault();
              if (e.keyCode === 38) {
                setAutocompleteSelected(
                  autocompleteSelected === 0 ? 0 : autocompleteSelected - 1
                );
                if (
                  typeof inputRef?.current?.focus === 'function' &&
                  autocompleteSelected === 0
                ) {
                  inputRef.current.focus();
                  setAutocompleteSelected(-1);
                }
              } else if (e.keyCode === 40) {
                let itemsLength = autocomplete[0]?.options?.length || 0;
                setAutocompleteSelected(
                  itemsLength > autocompleteSelected + 1
                    ? autocompleteSelected + 1
                    : autocompleteSelected
                );
              } else if (e.keyCode === 13) {
                if (typeof search === 'function') {
                  let selectedItemText: string =
                    autocomplete[0]?.options[autocompleteSelected].text;
                  setQuery(selectedItemText);
                  search(selectedItemText);
                  setAutocompleteSelected(-1);
                }
              }
            }}
          >
            {autocomplete[0]?.options?.map((item: any, index: number) => {
              return (
                <AutocompleteListItem
                  tabIndex={index}
                  key={`autocomplete_${item.text}`}
                  className={index === autocompleteSelected ? 'focus' : ''}
                  onFocus={() => {}}
                  onClick={() => {
                    setQuery(item.text);
                    if (typeof search === 'function') {
                      setQuery(item.text);
                      search(item.text);
                    }
                  }}
                >
                  {item.text}
                </AutocompleteListItem>
              );
            })}
          </Autocomplete>
        ) : null}
      </StyledSearchInputWrapper>
    );
  }
);

const AutocompleteWrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
`;

const StyledSearchInputWrapper = styled.div`
  width: 100%;
  position: relative;
  float: left;

  .loading-bar {
    position: absolute;
    left: 0;
    top: 60px;
  }

  .reset {
    transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 60px;
    background: #242525;
    float: left;
    color: white;

    .reset-icon {
      path {
        transition: fill 0.2s ease-in-out;
        fill: white;
      }
    }

    &:hover {
      cursor: pointer;
      background: #e8c893;
      color: black;

      .reset-icon {
        path {
          fill: black;
        }
      }
    }
  }
`;

const SearchField = styled.input`
  -webkit-text-size-adjust: none !important;
  -moz-text-size-adjust: none !important;
  -ms-text-size-adjust: none !important;
  text-size-adjust: none !important;
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 60px);
  height: 60px !important;
  margin-bottom: 0 !important;
  background-color: #242525;
  box-shadow: none !important;
  border: none !important;
  border-radius: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 20px !important;
  color: #fff !important;
  font-family: 'Proxima Nova', arial !important;
  font-weight: 400;
  float: left;
  outline: none;
  z-index: 3;
  -webkit-user-scalable: none;
  font-size: 16px !important;

  &::placeholder {
    color: #fff;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }

  @media screen and (max-width: 600px) {
    /*   font-size: 14px !important; */
    -webkit-user-scalable: none;
    font-size: 16px !important;
  }
`;

const SearchFieldBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 60px);
  height: 60px !important;
  background: #18181a;
  padding: 0 1em;
  z-index: 1;
`;

const autoCompleteHover = lighten('0.05', '#242525');
const Autocomplete = styled.ul<any>`
  box-sizing: border-box;
  position: absolute;
  top: 60px;
  margin: 0;
  width: 100%;
  float: left;
  background: #242525;
  list-style: none;
  max-width: 100%;
  padding: 0;
  z-index: 2;
  outline: none;
`;

const AutocompleteListItem = styled.li<any>`
  transition: background 0.1s ease-in-out;
  background: #242525;
  padding: 12px 18px;
  display: block;

  text-align: left;
  color: #fff;
  border-bottom: 1px solid #333;
  cursor: pointer;

  &.focus {
    background: ${autoCompleteHover};
  }

  &:hover {
    background: ${autoCompleteHover};
  }
`;

const AutocompletePlaceholder = styled.div`
  font-family: 'Proxima Nova', arial !important;
  font-weight: 400;
  position: absolute;
  top: -1px;
  left: 0;
  padding: 0 16px 0 20px;
  line-height: 63px;
  /* font-size: 23px !important; */
  color: rgb(100, 100, 100);
  z-index: 2;
  pointer-events: none;
  -webkit-user-scalable: none;
  font-size: 16px !important;

  @media screen and (max-width: 600px) {
    -webkit-user-scalable: none;
    /*     font-size: 14px !important; */
    font-size: 16px !important;
  }
`;
