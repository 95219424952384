import * as React from 'react';
import { lighten } from 'polished';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { replaceNaeringUrltag as replaceTag } from '../../../../../utils/helpers/replaceNaeringUrltag';

interface ITab {
  name?: string;
  naering: boolean;
  municipality?: string;
  alias: string;
  url?: string;
}

export const Office: React.FC<ITab> = ({
  name,
  naering,
  municipality,
  alias,
  url
}) => {
  const history = useHistory();
  return (
    <StyledOfficeItem
      onClick={() => {
        if (typeof window !== 'undefined') {
          if (naering) {
            window.location.href = `https://privatmeglern.no/naering/${replaceTag(
              alias
            )}`;
          } else if (url && /nybygg/.test(url)) {
            window.location.href = url;
          } else {
            history.push(`/${alias}`);
          }
        }
      }}
    >
      {municipality ? (
        <span className="municipality">{municipality}</span>
      ) : null}
      {name ? <span className="name">PrivatMegleren {name}</span> : null}
    </StyledOfficeItem>
  );
};

const hoverStyle = lighten(0.02, '#1c1c1c');
const StyledOfficeItem = styled.div`
  transition: background 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  width: 100%;
  min-height: 58px;
  padding: 1rem;
  background: #1c1c1c;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
    background: ${hoverStyle};
  }

  .name {
    color: #ddd;
    font-size: 0.8em;
    font-weight: 200;
    text-align: right;

    @media screen and (max-width: 600px) {
      max-width: 50%;
    }
  }
  .municipality {
    color: #e8c893;
    font-size: 1em;
  }

  @media screen and (max-width: 600px) {
    background: none;
    border-bottom: solid 1px rgb(40, 40, 40);
    margin-bottom: 0;
    padding: 1rem 0.5rem;
  }
`;
