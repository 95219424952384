import * as React from 'react';
import styled from 'styled-components';

interface IProgressBar {
  loading: boolean;
  className: string;
}

export const LoadingBar: React.FC<IProgressBar> = ({ className, loading }) => {
  return (
    <StyledProgressBar className={className}>
      <StyledBar className={loading ? 'run' : ''} />
    </StyledProgressBar>
  );
};

const StyledProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  overflow: hidden;
  background: rgb(255, 255, 255, 0.5);
`;

const StyledBar = styled.div`
  position: absolute;
  height: 100%;
  background: ${(props) => props.theme.colors.primary};

  &.run {
    animation: loader 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @keyframes loader {
    0% {
      width: 0%;
      left: 0;
      right: 0;
    }
    100% {
      width: 100%;
      left: 0;
      right: 0;
    }
  }
`;
